
// import { socketAutentica, socketDeAutentica } from './CodeeSocket';
// IMPLEMENTAR SISTEMA DE SOCKET // 
export function getToken() {
    return localStorage.getItem('token');
}

export function setCredentials(loginUser) {
    loginUser.token = "Bearer " + loginUser.token;
    localStorage.setItem('user_id', loginUser.uuid);
    localStorage.setItem('user_name', loginUser.nomeCompleto);
    localStorage.setItem('profile', JSON.stringify(loginUser.profile));
    localStorage.setItem('user_image', loginUser.fotoPerfil ? loginUser.fotoPerfil : '');
    localStorage.setItem('token', loginUser.token);
    // socketAutentica(loginUser.body.token);

    return loginUser;
}

export function deCredential(history = false) {
    const userId = localStorage.getItem('user_id');
    localStorage.removeItem('user_id', userId);    
    localStorage.removeItem('user_name');    
    localStorage.removeItem('token')
    localStorage.removeItem('user_image');
    localStorage.removeItem('profile');

    //socketDeAutentica(userId);
}